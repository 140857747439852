import { createApp } from 'vue'

import App from './App.vue'
// import VueRouter from 'vue-router'
import router from './router'
import store from './store'
import VueI18n from './plugins'
import '@/style/base.css'
// 引入ui 库
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import Vant from 'vant'
import 'vant/lib/index.css'
// 引入ui icon
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
createApp(App)
  .use(ElementPlus)
  .use(Vant)
  .use(VueI18n)
  .use(store)
  .use(router)
  .mount('#app')
// Vue.use(ElementPlus)
// Vue.use(Vant)
// Vue.use(VueI18n)
// // Vue.use(VueRouter)
// Vue.use(store)
// // Vue.use(VueRouter)
// // eslint-disable-next-line no-new
// new Vue({
//   el: '#app',
//   render: (h) => h(App),

//   // 全新配置项router
//   router: router
// })
